const getDiggleEnv = key => {
  if (
    window.DIGGLE_ENVIRONMENT?.[key] &&
    /^\${\s*[A-Z_]+\s*}$/.test(window.DIGGLE_ENVIRONMENT?.[key])
  ) {
    return ''
  }

  return window.DIGGLE_ENVIRONMENT?.[key] ?? ''
}

export let apiUrl =
  process.env.REACT_APP_API_URL ||
  getDiggleEnv('apiUrl') ||
  `http://${window.location.hostname}:9494`

if (process.env.NODE_ENV === 'development') {
  let API_VERSION = null

  if (window.location.search.slice(1).split('&').includes('DEVELOPMENT')) {
    sessionStorage.removeItem('diggle_use_production_api')
    sessionStorage.removeItem('diggle_use_staging_api')
  }

  if (
    sessionStorage.getItem('use_staging_api') === 'yes' ||
    window.location.search.slice(1).split('&').includes('STAGING')
  ) {
    API_VERSION = 'STAGING'
    sessionStorage.setItem('use_staging_api', 'yes')
    apiUrl = 'https://staging-api.diggle.com'
  } else if (
    sessionStorage.getItem('diggle_use_production_api') === 'yes' ||
    window.location.search.slice(1).split('&').includes('PRODUCTION')
  ) {
    API_VERSION = 'PRODUCTION'
  } else if (
    sessionStorage.getItem('diggle_use_test_api') === 'yes' ||
    window.location.search.slice(1).split('&').includes('TEST')
  ) {
    API_VERSION = 'TEST'
  }

  switch (API_VERSION) {
    case 'STAGING': {
      sessionStorage.setItem('use_staging_api', 'yes')
      apiUrl = 'https://staging-api.diggle.com'
      break
    }
    case 'PRODUCTION': {
      sessionStorage.setItem('diggle_use_production_api', 'yes')
      apiUrl = 'https://api.diggle.com'
      break
    }
    case 'TEST': {
      sessionStorage.setItem('diggle_use_test_api', 'yes')
      apiUrl = 'https://dev-api.diggle.com'
      break
    }
    default:
      break
  }

  if (API_VERSION) {
    const warning = document.createElement('div')

    Object.assign(warning.style, {
      backgroundColor: 'red',
      position: 'fixed',
      top: 0,
      left: '50%',
      zIndex: 100000,
      pointerEvents: 'none',
      transform: 'translateX(-50%)',
      padding: '3px 7px',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      textAlign: 'center',
      fontSize: '12px',
      opacity: 0.7,
    })

    warning.innerHTML = `
      <div style="font-size: 15px; font-weight: bold">
        ?${API_VERSION}
      </div>
      <div>
        Open ?DEVELOPMENT to turn off
      </div>
    `

    document.body.appendChild(warning)
  }
}

export const clientUrl =
  process.env.REACT_APP_CLIENT_URL ||
  getDiggleEnv('clientUrl') ||
  `${window.location.origin}/client`

export const stripeAPIKey = process.env.REACT_APP_STRIPE_API_KEY || getDiggleEnv('stripeAPIKey') || 'pk_test_Dq1qFQHtm8dp8BUXC2D1JJ7o'

// LEGACY
export const useDiggleIt =
  process.env.REACT_APP_USE_GODIGGLE || getDiggleEnv('useGoDiggle') === 'true'

export const PROJECTOR_SHOULD_WATCH_ANSWERS = true

export const STORE_ANSWER_TIMEOUT = 700
export const useRooms = false

export const googleApiKey =
  process.env.REACT_APP_GOOGLE_API_KEY || getDiggleEnv('googleApiKey') || null

export const googleClientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  getDiggleEnv('googleClientId') ||
  null
export const facebookAppId =
  process.env.REACT_APP_FACEBOOK_APP_ID || getDiggleEnv('facebookAppId') || null

export const OKTA_CLIENT_ID = '0oa4vyvkdudMhHtYv417'
export const OKTA_DOMAIN = 'okta.redcross.no'
export const CALLBACK_PATH = 'login/callback'
export const OKTA_AUTH_SERVER_ID = 'default'
export const sentryDSN = process.env.SENTRY_DSN || getDiggleEnv('sentryDSN') || null
export const sentryAuthToken = process.env.SENTRY_AUTH_TOKEN || getDiggleEnv('sentryAuthToken') || null

